div.ibpmodal {
  // display: none;
  position: fixed;
  z-index: 10000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.6);

  .ibpmodal-content {
    background-color: $card-bg;
    margin: 15% auto;
    padding: 20px;
    border: $card-border-width solid $card-border-color;
    border-radius: 10px;
    width: 80%;
    
    &.modal-small {
      width: 30%; 
    }
    
    &.modal-medium {
      width: 50%; 
    }

    &.modal-large {
      width: 80%; 
    }

    .close-button {
      position: relative;
      float: right;
      top: -10px;
      right: 0;
      color: $input-select-color;
      font-size: 28px;
      font-weight: bold;
      z-index: 11000;
      &:hover,
      &:focus {
        color: $input-select-color;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }
}