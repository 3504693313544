div#usersTable-container {
  height: 300px;
  overflow: auto;
  table > thead > tr > th {
    position: sticky;
    top: 0;
    background-color: $card-bg;
  }
}
tbody#usersTable > tr > td {
  cursor: pointer;
}
tbody#usersTable .selected td,
tbody#usersTable .selected + tr > td {
  border-top-color: white;
}
p#usersFooter {
  margin-top: 25px;
  margin-bottom: 0;
  color: $input-select-color;
}
div#user-details {
  display: none;
}
div#user-details,
div#details-tabs > div,
div#user-details-placeholder {
  transition: all 0.5s ease;
}
div#user-details span#user-details-login {
  text-transform: initial;
}
.usersList > li {
  display: inline-block;
  vertical-align: top;
  margin-right: 20px;
}
.usersList ul {
  list-style-type: none;
  margin-left: 10px;
  padding: 0;
}
.usersList ul li {
  display: block;
  margin: 5px 0;
}
.usersList ul li:before {
  content: "\2022";
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: 0;
}