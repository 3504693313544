div#search-input-container {
  float: left;
}
// input#search-input {
//   // z-index: 100;
// }
div#search-input-container > div > div {
  z-index: 300;
}
div#clear-search {
  display: none;
  position: relative;
  top: 10px;
  left: -10px;
  margin-left: -20px;
  font-weight: bold;
  color: $input-select-color;
  font-size: small;
  z-index: 200;
  cursor: pointer;
}
div#actions-button-container,
div#export-button-container,
div#actions-student-button-container,
div#details-year-selector {
  float: right;
  text-align: right;
}
div#enrollmentsTable-container {
  min-height: 350px;
  height: 30vh;
  overflow: hidden;
  table > thead > tr > th {
    position: sticky;
    top: 0;
    background-color: $card-bg;
    cursor: pointer;
  }
  .sort-up:before,
  .sort-down:after {
    border: 4px solid transparent;
    content: "";
    display: block;
    height: 0;
    right: 5px;
    top: 50%;
    position: absolute;
    width: 0;
  }
  .sort-up:before {
    border-bottom-color: $text-muted;
    margin-top: -9px;
  }
  .sort-down:after {
    border-top-color: $text-muted;
    margin-top: 1px;
  }
  table > tfoot {
    tr {
      border-top: 0;
      td {
        margin: 0;
        padding: 0;
        position: sticky;
        bottom: 0;
        background-color: transparent;
        #enrollments-progress-bar {
          margin: 0;
          padding: 0;
          height: 3px;
          width: 0;
          opacity: 1;
          background-color: $primary;
          transition-property: width, opacity;
          transition-duration: 1s;
        }
      }
    }
  }
}
tbody#enrollmentsTable > tr > td {
  cursor: pointer;
}
tbody#enrollmentsTable .selected-muted {
  background-color: $table-selected-muted-bg;
}
tbody#enrollmentsTable .selected {
  background-color: $table-selected-bg;
}
tbody#enrollmentsTable .selected td,
tbody#enrollmentsTable .selected + tr > td {
  border-top-color: white;
}
p#enrollmentsFooter {
  margin-top: 25px;
  margin-bottom: 0;
  color: $input-select-color;
}
span#student-details-ionisid {
  text-transform: capitalize;
}
span#student-details-login {
  text-transform: lowercase;
}
div#details-year-selector {
  float: right;
  text-align: right;
}
div#dropdownMenuYearsDiv {
  position: absolute;
  transform: translate3d(0px, -208px, 0px);
  top: 0px;
  left: 0px;
  will-change: transform;
  a.dropdown-item:focus,
  a.dropdown-item:hover {
    background-color: $input-placeholder-color;
  }
}
div#curriculum tbody tr td {
  cursor: pointer;
}
div#student-details,
div#details-tabs > div {
  transition: all 0.5s ease;
}