/*-------------------------------------------------------------------*/
/* === SCSS Compass Functions === */
@import "~compass-mixins/lib/compass";
@import "~compass-mixins/lib/animate";

/*-------------------------------------------------------------------*/
/* === Boostrap Main SCSS === */
@import "~bootstrap/scss/bootstrap";

/*-------------------------------------------------------------------*/
/* === Template mixins === */
@import "components/mixins/misc";
@import "components/mixins/animation";
@import "components/mixins/background";
@import "components/mixins/badges";
@import "components/mixins/blockqoute";
@import "components/mixins/buttons";
@import "components/mixins/breadcrumbs";
@import "components/mixins/cards";
@import "components/mixins/no-ui-slider";
@import "components/mixins/pagination";
@import "components/mixins/popovers";
@import "components/mixins/tooltips";

/*-------------------------------------------------------------------*/
/* === Core Styles === */
@import "components/reset";
@import "components/fonts";
@import "components/functions";
@import "components/background";
@import "components/typography";
@import "components/misc";
@import "components/footer";
@import "components/utilities";
@import "components/demo";

/*-------------------------------------------------------------------*/
/* === Components === */
// @import "components/accordions";
@import "components/badges";
// @import "components/bootstrap-alerts";
// @import "components/bootstrap-progress";
@import "components/buttons";
// @import "components/breadcrumbs";
@import "components/cards";
@import "components/checkbox-radio";
@import "components/dropdown";
@import "components/forms";
// @import "components/google-maps";
@import "components/icons";
@import "components/loaders/loaders";
// @import "components/lists";
// @import "components/modals";
// @import "components/pagination";
// @import "components/portfolio";
// @import "components/popover";
@import "components/preview";
@import "components/tables";
@import "components/tabs";
@import "components/themeToggle";
// @import "components/timeline";
@import "components/todo-list";
// @import "components/tooltips";
// @import "components/user-profile";
// @import "components/pricing-table";

/*-------------------------------------------------------------------*/
/* === Email === */
// @import "../modern-dark/shared/components/mail-components/mail-sidebar";
// @import "../modern-dark/shared/components/mail-components/mail-list-container";
// @import "../modern-dark/shared/components/mail-components/messagecontent";

/*-------------------------------------------------------------------*/
/* === Plugin overrides === */
@import "components/plugin-overrides/ace";
@import "components/plugin-overrides/avgrund";
@import "components/plugin-overrides/chartist";
@import "components/plugin-overrides/c3";
@import "components/plugin-overrides/codemirror";
@import "components/plugin-overrides/colcade";
@import "components/plugin-overrides/colorpicker";
@import "components/plugin-overrides/context-menu";
@import "components/plugin-overrides/data-tables";
@import "components/plugin-overrides/datepicker";
@import "components/plugin-overrides/dropify";
@import "components/plugin-overrides/dropzone";
@import "components/plugin-overrides/flot-chart";
@import "components/plugin-overrides/full-calendar";
@import "components/plugin-overrides/google-charts";
@import "components/plugin-overrides/icheck";
@import "components/plugin-overrides/jquery-file-upload";
@import "components/plugin-overrides/js-grid";
@import "components/plugin-overrides/jvectormap";
@import "components/plugin-overrides/justguage.scss";
@import "components/plugin-overrides/light-gallery";
@import "components/plugin-overrides/listify";
@import "components/plugin-overrides/morris.scss";
@import "components/plugin-overrides/no-ui-slider";
@import "components/plugin-overrides/owl-carousel";
@import "components/plugin-overrides/progressbar-js";
@import "components/plugin-overrides/pws-tabs";
@import "components/plugin-overrides/quill";
@import "components/plugin-overrides/rating";
@import "components/plugin-overrides/select2";
@import "components/plugin-overrides/summernote";
@import "components/plugin-overrides/sweet-alert";
@import "components/plugin-overrides/switchery";
@import "components/plugin-overrides/tags";
@import "components/plugin-overrides/tinymce";
@import "components/plugin-overrides/toast";
@import "components/plugin-overrides/typeahead";
@import "components/plugin-overrides/wysieditor";
@import "components/plugin-overrides/x-editable";
@import "components/plugin-overrides/wizard";

/*-------------------------------------------------------------------*/
/* === Landing screens === */
@import "components/landing-screens/auth";

@import './api';
@import './navbar';
@import './sidebar';
@import './enrollments';
@import './permissions';
@import './users';
@import './modal';
@import './layouts';
@import './vertical/vertical-wrapper';

#sentry-feedback {
  --bottom: 3rem;
}

#login-fullpage {
  height: 100%;
}